import React from 'react';
import styles from './FooterTextView.module.css';

interface FooterTextViewProps {
    title: string;
    description: string;
}

const FooterTextView: React.FC<FooterTextViewProps> = ({ title, description }) => {
    const isEmail = /\S+@\S+\.\S+/.test(description); // Check if description looks like an email address

    const handleEmailClick = () => {
        window.location.href = `mailto:${description}`; // Open default email client with the email address
    };

    const parsedDescription = description.split('<br />').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return (
        <div className={styles.footerTextView}>
            <p className={styles.title}>{title}</p>
            <p className={isEmail ? styles.emailDescription : styles.description} onClick={isEmail ? handleEmailClick : undefined}>
                {parsedDescription}
            </p>
        </div>
    );
};
// const FooterTextView: React.FC<{ title: string; description: string }> = ({ title, description }) => {
//
//     return (
//         <div className={styles.textView}>
//             <h3 className={styles.title}>{title}</h3>
//             <p className={styles.description}>{description}</p>
//         </div>
//     );
// };

export default FooterTextView;
