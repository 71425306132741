// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterTextView_textView__NG7L8 {
    display: flex;
    flex-direction: column;
    /*justify-content: flex;*/
    /*margin: 0 10px; !* Adjust the margin value as needed *!*/
    /* or */
    /*padding: 0 10px; !* Adjust the padding value as needed *!*/
}

.FooterTextView_title__Gx4G1 {
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 2px;
    color: rgba(60, 60, 67, 0.6);
    text-align: left;
    margin: 0 10px;
}

.FooterTextView_description__Xa9mO {
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 2px;
    color: #000000;
    margin-top: 0;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}

.FooterTextView_emailDescription__EiNJL {
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 2px;
    text-decoration-line: underline;
    color: #007AFF;
    margin-top: 0;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterTextView.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,0DAA0D;IAC1D,OAAO;IACP,4DAA4D;AAChE;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,4BAA4B;IAC5B,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,+BAA+B;IAC/B,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".textView {\n    display: flex;\n    flex-direction: column;\n    /*justify-content: flex;*/\n    /*margin: 0 10px; !* Adjust the margin value as needed *!*/\n    /* or */\n    /*padding: 0 10px; !* Adjust the padding value as needed *!*/\n}\n\n.title {\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    letter-spacing: 2px;\n    color: rgba(60, 60, 67, 0.6);\n    text-align: left;\n    margin: 0 10px;\n}\n\n.description {\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 140%;\n    letter-spacing: 2px;\n    color: #000000;\n    margin-top: 0;\n    text-align: left;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.emailDescription {\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 140%;\n    letter-spacing: 2px;\n    text-decoration-line: underline;\n    color: #007AFF;\n    margin-top: 0;\n    text-align: left;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textView": `FooterTextView_textView__NG7L8`,
	"title": `FooterTextView_title__Gx4G1`,
	"description": `FooterTextView_description__Xa9mO`,
	"emailDescription": `FooterTextView_emailDescription__EiNJL`
};
export default ___CSS_LOADER_EXPORT___;
