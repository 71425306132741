// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../globalStyles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_container__wOY5E {
    width: 80%;
    min-height: 80vh;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.Home_homeTitle__3BUSj {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 94%;
}

.Home_homeDescription__QQK0F {
    margin-top: 0;
    padding-left: 60%;
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.6);
}

.Home_logo__0ULJS {
    align-self: flex-start;
    width: 80px;
    height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home.module.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB","sourcesContent":["@import '../globalStyles.css';\n\n.container {\n    width: 80%;\n    min-height: 80vh;\n    padding-left: 10%;\n    padding-right: 10%;\n    padding-top: 5%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n}\n\n.homeTitle {\n    margin-top: 0;\n    margin-bottom: 0;\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 96px;\n    line-height: 94%;\n}\n\n.homeDescription {\n    margin-top: 0;\n    padding-left: 60%;\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 28px;\n    line-height: 140%;\n    color: rgba(60, 60, 67, 0.6);\n}\n\n.logo {\n    align-self: flex-start;\n    width: 80px;\n    height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Home_container__wOY5E`,
	"homeTitle": `Home_homeTitle__3BUSj`,
	"homeDescription": `Home_homeDescription__QQK0F`,
	"logo": `Home_logo__0ULJS`
};
export default ___CSS_LOADER_EXPORT___;
