// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* globalStyles.css */

body {
    font-family: 'Neue Machina', Arial, sans-serif;
}

h2 {
    font-size: 2rem;
    color: #333;
}

h3 {
    font-size: 1.5rem;
    color: #333;
}

p {
    font-size: 1.2rem;
    color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/globalStyles.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,8CAA8C;AAClD;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","sourcesContent":["/* globalStyles.css */\n\nbody {\n    font-family: 'Neue Machina', Arial, sans-serif;\n}\n\nh2 {\n    font-size: 2rem;\n    color: #333;\n}\n\nh3 {\n    font-size: 1.5rem;\n    color: #333;\n}\n\np {\n    font-size: 1.2rem;\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
