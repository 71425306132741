// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../globalStyles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__EwVYi {
    color: white;
    width: 80%;
    min-height: 5vh;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    justify-content: space-around;
    align-items: flex-start;
}

@media screen and (max-width: 600px) {
    .Footer_footer__EwVYi {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,UAAU;IACV,eAAe;IACf,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,6BAA6B;IAC7B,uBAAuB;AAC3B;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["@import '../globalStyles.css';\n\n.footer {\n    color: white;\n    width: 80%;\n    min-height: 5vh;\n    bottom: 0;\n    display: flex;\n    flex-direction: row;\n    padding-left: 10%;\n    padding-right: 10%;\n    padding-bottom: 2%;\n    justify-content: space-around;\n    align-items: flex-start;\n}\n\n@media screen and (max-width: 600px) {\n    .footer {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__EwVYi`
};
export default ___CSS_LOADER_EXPORT___;
