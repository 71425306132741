import React, {useState} from 'react';
import styles from './RoundedButton.module.css';

interface RoundedButtonProps {
    cornerRadius?: number;
    onClick: () => void;
    children: React.ReactNode;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({ cornerRadius = 5, onClick, children }) => {
    const [isHovered, setIsHovered] = useState(false);
    const buttonStyle = {
        borderRadius: `${cornerRadius}px`,
        backgroundColor: isHovered ? '#007AFF' : '#000',
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClick = () => {
        onClick(); // Call the onClick event handler
    };
    return (
        <button
            className={styles.roundedButton}
            style={buttonStyle}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
        </button>
    );
};

export default RoundedButton;
