import React from 'react';
import styles from './Footer.module.css';
import FooterTextView from './FooterTextView';

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
                <FooterTextView title={"Company name"} description={"AppKeeper Pte. Ltd."} />
                <FooterTextView title={"Address"} description={"68 Circular Road <br />#02-01<br /> 049422, Singapore"} />
                <FooterTextView title={"Registration Number"} description={"202321930C"} />
                <FooterTextView title={"Email for business inquiries"} description={"contact@appkeeper.ltd"} />
        </footer>
    );
};

export default Footer;
