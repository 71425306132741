// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoundedButton_roundedButton__fHTf6 {
    width: 100%;
    height: 13.732394366vh;
    background-color: #000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: background-color 0.25s;
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.RoundedButton_roundedButton__fHTf6:hover {
    background-color: #61dafb;
}
`, "",{"version":3,"sources":["webpack://./src/components/RoundedButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,eAAe;IACf,kCAAkC;IAClC,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".roundedButton {\n    width: 100%;\n    height: 13.732394366vh;\n    background-color: #000;\n    color: #ffffff;\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.25s;\n    font-family: 'Neue Machina';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 44px;\n    line-height: 120%;\n    margin-top: 5%;\n    margin-bottom: 5%;\n}\n\n.roundedButton:hover {\n    background-color: #61dafb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roundedButton": `RoundedButton_roundedButton__fHTf6`
};
export default ___CSS_LOADER_EXPORT___;
