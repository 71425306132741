import React from 'react';
import styles from './Home.module.css';
import logo from "../logo.svg";
import RoundedButton from "./RoundedButton";

const Home: React.FC = () => {
    const handleContactUsButtonClick = () => {
        window.location.href = `mailto:contact@appkeeper.ltd`;
    };
    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </div>
            <div className={styles.homeTitle}>a team of enthusiasts
                which creates
            </div>
            <div  className={styles.homeDescription}>
                <span style={{color: '#007AFF'}}>iOS applications</span> and <span style={{color: '#000'}}>web services</span><br />
                in the field of social media
            </div>
            <RoundedButton cornerRadius={1000}  onClick={handleContactUsButtonClick}>
                contact us
            </RoundedButton>
        </div>
    );
};

export default Home;
